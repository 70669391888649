import React, { Component } from "react";
import {createRoot} from "react-dom/client";
import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import PageVisibility from "react-page-visibility";
import Navbar from "./Components/Navbar";
import AdConfigurationMasterDetailView from "./Components/ServiceModules/AdConfigurationService/AdConfigurationMasterDetailView";
import OriginCDNMapperMasterDetailView from "./Components/ServiceModules/OriginCDNMapper/OriginCDNMapperMasterDetailView";
import ARAMasterDetailView from "./Components/ServiceModules/ARA/ARAMasterDetailView";
import AdmiralAuditMasterDetailView from "./Components/ServiceModules/AdmiralAudit/AdmiralAuditMasterDetailView";
import AdmiralAdminMasterDetailView from "./Components/ServiceModules/AdmiralAdmin/AdmiralAdminMasterDetailView";
import AdmiralGroupsMasterDetailView from "./Components/ServiceModules/AdmiralGroups/AdmiralGroupsMasterDetailView";
import TokenFpMasterDetailView from "./Components/ServiceModules/TokenFP/TokenFpMasterDetailView";
import TokenMdMasterDetailView from "./Components/ServiceModules/TokenMD/TokenMdMasterDetailView";
import MediumAdminMasterDetailView from "./Components/ServiceModules/MediumAdmin/MediumAdminMasterDetailView";
import MediumAssetRegistration from "./Components/ServiceModules/MediumAssetRegistration/MediumAssetRegistration";
import ErrorReportMasterDetailView from "./Components/ServiceModules/ErrorReport/ErrorReportMasterDetailView";
import UserManagementMasterDetailView from "./Components/ServiceModules/UserManagement/UserManagementMasterDetailView";
import SynapseMetadataMasterDetailView from "./Components/ServiceModules/SynapseMetadata/SynapseMetadata";
import RouteMasterDetailView from './Components/ServiceModules/RouteManagement/RouteMasterDetailView';
import AdobeTempPassMasterDetailView from "./Components/ServiceModules/AdobeTempPass/AdobeTempPassMasterDetailView";
import MediumAuditMasterDetailView from "./Components/ServiceModules/MediumAudit/MediumAuditMasterDetailView";
import TokenAdminMasterView from "./Components/ServiceModules/TokenAdmin/TokenAdminMasterView";
import FreeviewMasterDetailView from "./Components/ServiceModules/Freeview/FreeviewMasterDetailView";
import FreeviewTokenDetailView from "./Components/ServiceModules/Freeview/FreeviewTokenDetailView";
import DeploymentMasterDetailView from "./Components/ServiceModules/DeploymentManager/DeploymentMasterDetailView";
import S2SToolsMasterDetailView from "./Components/ServiceModules/S2STools/S2SToolsMasterDetailView";
import SpotlightMetadataMasterDetailView from "./Components/ServiceModules/Spotlight/SpotlightMetadataMasterDetailView";
import RelaysAuditMasterDetailView from "./Components/ServiceModules/RelaysAudit/RelaysAuditMasterDetailView";
import RelaysAdminMasterDetailView from "./Components/ServiceModules/RelaysAdmin/RelaysAdminMasterDetailView";
import TimeMachineMasterDetailView from "./Components/ServiceModules/TimeMachine/TimeMachineMasterDetailView";
import TimeMachineAuditMasterDetailView from "./Components/ServiceModules/TimeMachineAudit/TimeMachineAuditMasterDetailView";
import KnoxMasterDetailView from "./Components/ServiceModules/Knox/KnoxMasterDetailView";
import SundialMasterDetailView from "./Components/ServiceModules/Sundial/SundialMasterDetailView";
import BlackoutSoupMasterDetailView from "./Components/ServiceModules/Blackout/BlackoutSoupMasterDetailView";
import DegradationDashboardMasterDetailView from "./Components/ServiceModules/DegradationDashboard/DegradationDashboardMasterDetailView";
import MVPDConfigEditorMasterDetailView from "./Components/ServiceModules/MVPDConfigEditor/MVPDConfigEditorMasterDetailView";
import ApplicationReleaseManager from "./Components/ServiceModules/ApplicationReleaseManager/ApplicationReleaseManager";
import TVEManagerEntityEditor from "./Components/ServiceModules/TVEManagerEntityEditor/TVEManagerEntityEditor";
import CampaignManager from "./Components/ServiceModules/CampaignManager/CampaignManager";
import LimboMasterDetailView from "./Components/ServiceModules/Limbo/LimboMasterDetailView";
import TokenGeofencingMasterDetailView from "./Components/ServiceModules/TokenGeofencing/TokenGeofencingMasterDetailView";
import TokenStreamSettingsMasterDetailView from "./Components/ServiceModules/TokenStreamSettings/TokenStreamSettingsMasterDetailView";
import SMPAuditMasterDetailView from "./Components/ServiceModules/SMPAudit/SMPAuditMasterDetailView";
import SMPMetadataMasterDetailView from "./Components/ServiceModules/SMPMetadata/SMPMetadataMasterDetailView";
import StateBasedPlayMetadata from "./Components/ServiceModules/StateBasedPlayMetadata/StateBasedPlayMetadata";
import ListSchemataMasterDetailView from "./Components/ServiceModules/StateBasedPlayListSchemata/ListSchemataMasterDetailView";
import ContentSchemataMasterDetailView from "./Components/ServiceModules/StateBasedPlayContentSchemata/ContentSchemataMasterDetailView";
import GeoMetadataMasterDetailView from "./Components/ServiceModules/GeoMetadata/GeoMetadataMasterDetailView";
import GeoOverridesMasterDetailView from "./Components/ServiceModules/GeoOverrides/GeoOverridesMasterDetailView";
import CDNToolsMasterView from "./Components/ServiceModules/CDNTools/CDNToolsMasterView";
import ConsensoAuditMasterDetailView from "./Components/ServiceModules/ConsensoAudit/ConsensoAuditMasterDetailView";
import ConsensoMetadataMasterDetailView from "./Components/ServiceModules/ConsensoMetadata/ConsensoMetadataMasterDetailView";
import ConsensoAssetsMasterDetailView from "./Components/ServiceModules/ConsensoAssets/ConsensoAssetsMasterDetailView";
import AccioMetadataMasterDetailView from "./Components/ServiceModules/AccioMetadata/AccioMetadataMasterDetailView";
import AccioMaturityMasterDetailView from "./Components/ServiceModules/AccioMaturity/AccioMaturityMasterDetailView";
import AccioEventsAdminMasterDetailView from "./Components/ServiceModules/AccioEventsAdmin/AccioEventsAdminMasterDetailView";
import AccioEventsAuditMasterDetailView from "./Components/ServiceModules/AccioEventsAudit/AccioEventsAuditMasterDetailView";
import AccioAdminMasterDetailView from "./Components/ServiceModules/AccioAdmin/AccioAdminMasterDetailView";
import EventDispatcherMasterDetailView from "./Components/ServiceModules/EventDispatcher/EventDispatcherMasterDetailView";
import CreditNotesAdminMasterDetailView from "./Components/ServiceModules/CreditNotes/CreditNotesAdminMasterDetailView";
import TimeMachineRundownCMSMasterDetailView from "./Components/ServiceModules/TimeMachineRundownCMS/TimeMachineRundownCMSMasterDetailView";
import TimeMachineScopeCMSMasterDetailView from "./Components/ServiceModules/TimeMachineScopeCMS/TimeMachineScopeCMSMasterDetailView";
import TimeMachineCMSAuditMasterDetailView from "./Components/ServiceModules/TimeMachineCMSAudit/TimeMachineCMSAuditMasterDetailView";
import TimeMachineDefaultImagesMasterDetailView from "./Components/ServiceModules/TimeMachineDefaultImages/TimeMachineDefaultImagesMasterDetailView";
import TimeMachineTaxonomyViewer from "./Components/ServiceModules/TimeMachineTaxonomyViewer/TimeMachineTaxonomyViewer";
import ScaleratorAdminMasterDetailsView from "./Components/ServiceModules/Scalerator/ScaleratorAdminMasterDetailsView";

import {toast, SemanticToastContainer} from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import Dashboard from "./Components/Dashboard/Dashboard";
import AuthProvider from "./Services/AuthDataProvider";
import Unauthorized from "./Components/Unauthorized";
import NotFound from "./Components/NotFound/NotFound";
import SecureRoute from "./Components/SecureRoute";

import "./index.css";
import "react-table-v6/react-table.css";
import Login from "./Views/Login";
import TimeMachineShowSearchMasterDetailView
    from "./Components/ServiceModules/TimeMachineShowSearch/TimeMachineShowSearchMasterDetailView";


const _log = console.log.bind(console);
console.log = (...args) => {
    if (["LOCAL", "DEV", "REF"].includes(process.env.ENVIRONMENT_NAME)) {
        _log.apply(null, args);
    }
};

const SERVICE_GROUPS = [
    {
        "groupName": "Synapse",
        "services": [
            {
                "service": "synapse",
                "module": "users",
                "name": "User Management",
                "path": "/userMgt",
                "description": "Manage user permissions",
                "icon": "users",
                "ipFiltered": true,
                "environment": process.env.SYNAPSE_CORE_ENV
            },
            {
                "service": "synapse",
                "module": "routes",
                "name": "Route Management",
                "path": "/routeMgt",
                "description": "Add/Remove/Update Routes in Synapse Core",
                "icon": "plane",
                "ipFiltered": true,
                "environment": process.env.SYNAPSE_CORE_ENV
            },
            {
                "service": "synapse",
                "module": "metadata",
                "name": "Metadata",
                "path": "/synapseMetadata",
                "description": "Manage metadata for Synapse Core",
                "icon": "terminal",
                environment: process.env.SYNAPSE_CORE_ENV
            }
        ]
    },
    {
        "groupName": "Geo",
        "services": [
            {
                "service": "geo",
                "module": "metadata",
                "name": "Geo Metadata",
                "path": "/geo",
                "description": "View and modify metadata for Geo",
                "icon": "map marker alternate",
                "environment": process.env.GEO_ENV
            },
            {
                "service": "geo",
                "module": "overrides",
                "name": "Geo Overrides",
                "path": "/geoOverrides",
                "description": "View and modify overrides for Geo",
                "icon": "map marker",
                "environment": process.env.GEO_ENV
            }
        ]
    },
    {
        "groupName": "Medium",
        "services": [
            {
                "service": "medium",
                "module": "ad-configuration",
                "name": "Ad Configuration Service",
                "path": "/ad-configuration",
                "description": "Add/Remove/Update ad configurations",
                "icon": "adversal",
                "environment": process.env.AD_CONFIG_ENV
            },
            {
                "service": "medium",
                "module": "origin-cdn-mapper",
                "name": "Origin-CDN Mapper",
                "path": "/origin-cdn-mapper",
                "description": "Add/Remove/Update CDN map configurations",
                "icon": "map signs",
                "environment": process.env.ORIGIN_CDN_MAPPER_ENV
            },
            {
                "service": "medium",
                "module": "admin",
                "name": "Medium Admin",
                "path": '/mediumAdmin',
                "description": "Manage metadata, cache invalidation, and asset registration",
                "icon": "medium",
                "environment": process.env.MEDIUM_ENV
            },
            {
                "service": "medium",
                "module": "asset-registration",
                "name": "Media Asset Registration",
                "path": '/assetRegistration',
                "description": "Directly ingest Media JSON payloads",
                "icon": "maxcdn",
                "environment": process.env.MEDIUM_ENV
            },
        ]
    },
    {
        "groupName": "CDN Tools",
        "services": [
            {
                "service": "medium",
                "module": "cdn",
                "name": "Cache Purge",
                "path": "/cachePurge",
                "description": "Purge Cached Entries On CDN",
                "icon": "server m",
                "environment": process.env.MEDIUM_ENV
            }
        ]
    },
    {
        "groupName": "Token",
        "services": [
            {
                "service": "token",
                "module": "free-preview",
                "name": "Token Free Preview",
                "path": "/tokenfp",
                "description": "Allow users access to content in specific time windows",
                "icon": "ticket",
                "environment": process.env.TOKEN_FP_ENV
            },
            {
                "service": "token",
                "module": "metadata",
                "name": "Token Metadata",
                "path": "/tokenmd",
                "description": "Visualize and Update Token Metadata",
                "icon": "ticket",
                "environment": process.env.TOKEN_MD_ENV
            },
            {
                "service": "token",
                "module": "tools",
                "open": true,
                "name": "Token Admin",
                "path": "/tokenAdmin",
                "description": "Generate and view various token from Token Service",
                "icon": "ticket",
                "environment": process.env.TOKEN_ADM_ENV
            },
            {
                "service": "token",
                "module": "geofencing",
                "name": "Token Geofencing",
                "path": "/tokenGeofencing",
                "description": "Manage Token geolocation settings",
                "icon": "globe",
                "environment": process.env.TOKEN_MD_ENV
            },
            {
                "service": "token",
                "module": "stream-settings",
                "name": "Token Stream Settings",
                "path": "/tokenStreamSettings",
                "description": "Manage TurnerToken and stream concurrency settings",
                "icon": "sliders horizontal",
                "environment": process.env.TOKEN_MD_ENV
            }
        ]
    },
    {
        "groupName": "Free Preview",
        "services": [
            {
                "service": "adobe-temp-pass",
                "module": "reset-tool",
                "open": true,
                "name": "Adobe Temp Pass Reset Tool",
                "path": "/adobeTempPass",
                "description": "Reset Adobe Temp Pass for a specific device",
                "icon": "key",
                "environment": process.env.ADOBE_TEMP_PASS_ENV
            },
            {
                "service": "freeview",
                "module": "reset",
                "name": "Freeview Reset Tool",
                "path": "/freeview",
                "description": "Reset Freeview access for a specific device",
                "icon": "stopwatch",
                "environment": process.env.FREEVIEW_MD_ENV,
            },
            {
                "service": "freeview",
                "module": "admin",
                "name": "Freeview Admin",
                "path": "/freeviewAdmin",
                "description": "Manage Freeview Configuration",
                "icon": "pencil",
                "environment": process.env.FREEVIEW_MD_ENV
            }
        ]
    },
    {
        "groupName": "Live2VOD",
        "services": [
            {
                "service": "smp",
                "module": "audit",
                "name": "Stream Message Processor Audit",
                "path": "/smpAudit",
                "description": "Live Stream Processing Audit",
                "icon": "film",
                "environment": process.env.SMP_ENV
            },
            {
                "service": "smp",
                "module": "metadata",
                "name": "Stream Message Processor Metadata",
                "path": "/smpMD",
                "description": "Live Stream Processing Admin",
                "icon": "microchip",
                "environment": process.env.SMP_ENV
            },
        ]
    },
    {
        "groupName": "Asset Management",
        "services": [
            {
                "service": "consenso",
                "module": "assets",
                "name": "Consenso Assets",
                "path": "/consensoAssets",
                "description": "View information about assets submitted to Consenso.",
                "icon": "tags",
                "environment": process.env.CONSENSO_ENV
            },
            {
                "service": "consenso",
                "module": "audit",
                "name": "Consenso Audit",
                "path": "/consensoAudit",
                "description": "View information about media that have been submitted to Consenso.",
                "icon": "handshake",
                "environment": process.env.CONSENSO_ENV
            },
            {
                "service": "consenso",
                "module": "metadata",
                "name": "Consenso Metadata",
                "path": "/consensoMD",
                "description": "Update metadata in Consenso.",
                "icon": "handshake outline",
                "environment": process.env.CONSENSO_ENV
            },
            {
                "service": "medium",
                "module": "audit",
                "name": "Medium Audit",
                "path": "/mediumAudit",
                "description": "Review Medium ingest registration payloads",
                "icon": "medium m",
                "environment": process.env.MEDIUM_ENV
            },
            {
                "service": "relays",
                "module": "admin",
                "name": "Relays Admin",
                "path": "/relaysAdmin",
                "description": "Perform administrative actions for Relays",
                "icon": "hubspot",
                "environment": process.env.RELAYS_ENV
            },
            {
                "service": "relays",
                "module": "audit",
                "name": "Relays Audit",
                "path": "/relaysAudit",
                "description": "Send various calls to alter the state of messages in the relays queues",
                "icon": "sitemap",
                "environment": process.env.RELAYS_ENV
            },
            {
                "service": "admin",
                "module": "sundial",
                "open": true,
                "name": "Sundial",
                "path": "/sundial",
                "description": "Investigate issues surrounding a media ID",
                "icon": "power off",
                "environment": process.env.MEDIUM_ENV
            },
        ]
    },
    {
        "groupName": "Time Machine",
        "services": [
            {
                "service": "time-machine",
                "module": "admin",
                "name": "Time Machine Admin",
                "path": "/timeMachineAdmin",
                "description": "Manage Admin operations in Time Machine. Including setting metadata and purging feeds.",
                "icon": "car",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            },
            {
                "service": "time-machine",
                "module": "audit",
                "name": "Time Machine Audit",
                "path": "/timeMachineAudit",
                "description": "View ingestion audits for Time Machine.",
                "icon": "calendar alternate",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            },
            {
                "service": "time-machine",
                "module": "cms",
                "name": "Time Machine Rundown CMS",
                "path": "/timeMachineRundownCMS",
                "description": "View and edit overrides and images for Time Machine Shows in the rundown.",
                "icon": "clock",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            },
            {
                "service": "time-machine",
                "module": "cms",
                "name": "Time Machine Scope CMS",
                "path": "/timeMachineScopeCMS",
                "description": "View and edit overrides and images for categories in Time Machine, regardless of whether they are in the rundown.",
                "icon": "clock outline",
                "flipped": "vertically",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            },
            {
                "service": "time-machine",
                "module": "cms-audit",
                "name": "Time Machine CMS Audit",
                "path": "/timeMachineCMSAudit",
                "description": "View audit information for override and image updates for Time Machine.",
                "icon": "calendar outline alternate",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            },
            {
                "service": "time-machine",
                "module": "cms-default-images",
                "name": "Time Machine CMS Default Images",
                "path": "/timeMachineCMSDefaultImages",
                "description": "View and edit default images for Time Machine.",
                "icon": "images outline",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            },
            {
                "service": "time-machine",
                "module": "show-search",
                "name": "Time Machine Shows",
                "path": "/timeMachineShowSearch",
                "description": "View shows in Time Machine.",
                "icon": "tv",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            },
            {
                "service": "time-machine",
                "module": "taxonomy-viewer",
                "name": "Time Machine Taxonomy Viewer",
                "path": "/timeMachineTaxonomyViewer",
                "description": "View taxonomies for Time Machine.",
                "icon": "linkify",
                "environment": process.env.TIME_MACHINE_ADMIN_ENV
            }
        ]
    },
    {
        "groupName": "State-Based Play",
        "services": [
            {
                "service": "sbp",
                "module": "metadata",
                "name": "State-Based Play Metadata",
                "path": "/sbpMD",
                "description": "Configure app metadata for State-Based Play",
                "icon": "play",
                "environment": process.env.SBP_ENV
            },
            {
                "service": "sbp",
                "module": "content-schemata",
                "name": "State-Based Play Content Schemata",
                "path": "/contentSchemata",
                "description": "Configure list schemata for State-Based Play",
                "icon": "video play",
                "environment": process.env.SBP_ENV
            },
            {
                "service": "sbp",
                "module": "list-schemata",
                "name": "State-Based Play List Schemata",
                "path": "/listSchemata",
                "description": "Configure content schemata for State-Based Play",
                "icon": "tasks",
                "environment": process.env.SBP_ENV
            }
        ]
    },
    {
        "groupName": "Blackout",
        "services": [
            {
                "service": "blackout",
                "module": "soup",
                "open": true,
                "name": "Blackout Soup",
                "path": "/blackout",
                "description": "Check if a stream is blacked out in requested locations",
                "icon": "low vision",
                "environment": process.env.BLACKOUT_ENV
            }
        ]
    },
    {
        "groupName": "Ads",
        "services": [
            {
                "service": "admiral",
                "module": "audit",
                "name": "Admiral Audit",
                "path": "/admiralAudit",
                "description": "Centralized Instant Video Ingest (IVI) Audit",
                "icon": "ship",
                "environment": process.env.ADMIRAL_ENV
            },
            {
                "service": "admiral",
                "module": "admin",
                "name": "Admiral Admin",
                "path": "/admiralAdmin",
                "description": "Centralized Instant Video Ingest (IVI) Admin",
                "icon": "anchor",
                "environment": process.env.ADMIRAL_ENV
            },
            {
                "service": "admiral",
                "module": "groups",
                "name": "Admiral Groups",
                "path": "/admiralGroups",
                "description": "Centralized Instant Video Ingest (IVI) groups",
                "icon": "group",
                "environment": process.env.ADMIRAL_ENV
            },
            {
                "service": "admiral",
                "module": "campaign-manager",
                "name": "Campaign Manager",
                "path": "/campaignManager",
                "description": "Create substitution rules for admiral templates.",
                "icon": "map outline",
                "environment": process.env.ADMIRAL_ENV
            },
        ]
    },
    {
        "groupName": "Administration",
        "services": [
            {
                "service": "admin",
                "module": "ara",
                "name": "ARA",
                "path": "/ara",
                "description": "Create App IDs via the Application Registration API",
                "icon": "untappd",
                "environment": process.env.ARA_ENV
            },
        ]
    },
    {
        "groupName": "Other",
        "services": [
            {
                "service": "admin",
                "module": "error-report",
                "name": "Error Report",
                "path": "/error-report",
                "description": "View Details on Errors in a specified environment",
                "icon": "warning sign",
                "environment": process.env.ERROR_REPORT_ENV
            },
            {
                "service": "limbo",
                "module": "audit",
                "name": "Limbo",
                "path": "/limbo",
                "description": "View and debug problems with the end user experience from the session data collected by UTC",
                "icon": "wrench",
                "environment": process.env.LIMBO_ENV
            },
            {
                "service": "spotlight",
                "module": "metadata",
                "name": "Spotlight Metadata",
                "path": "/spotlightMD",
                "description": "Configure app metadata for Spotlight",
                "icon": "feed",
                "environment": process.env.SPOTLIGHT_ENV
            }
        ]
    },
    {
        "groupName": "Accio",
        "services": [
            {
                "name": "Accio Metadata",
                "path": "/accioMetadata",
                "description": "Modify application metadata for Accio",
                "service": "accio",
                "module": "metadata",
                "icon": "magic",
                "environment": process.env.ACCIO_ENV
            },
            {
                "name": "Maturity Settings",
                "path": "/accioMaturity",
                "description": "Modify national age-of-maturity settings for Accio",
                "service": "accio",
                "module": "maturity",
                "icon": "beer",
                "environment": process.env.ACCIO_ENV
            },
            {
                "name": "Events Admin",
                "path": "/accioEvents",
                "description": "Create and update events in Accio",
                "service": "accio",
                "module": "events",
                "icon": "book",
                "environment": process.env.ACCIO_ENV
            },
            {
                "name": "Events Audit",
                "path": "/accioAudit",
                "description": "View user events in Accio",
                "service": "accio",
                "module": "audit",
                "icon": "bolt",
                "environment": process.env.ACCIO_ENV
            },
            {
                "name": "Accio Admin",
                "path": "/accioAdmin",
                "description": "Modify pre-defined settings in Accio",
                "service": "accio",
                "module": "settings",
                "icon": "settings",
                "environment": process.env.ACCIO_ENV
            }
        ]
    },
    {
        "groupName": "Security",
        "services": [
            {
                "service": "admin",
                "module": "s2s-tools",
                "open": true,
                "name": "Server-to-Server Tools",
                "path": "/s2s",
                "description": "Create and validate S2S tokens",
                "icon": "lock",
                "environment": process.env.SYNAPSE_CORE_ENV
            },
            {
                "service": "knox",
                "module": "key-pairs",
                "name": "Knox",
                "path": "/knox",
                "description": "Create RSA key pairs or save public keys",
                "icon": "chess rook",
                "environment": process.env.KNOX_ENV
            }
        ]
    },
    {
        "groupName": "TVE Manager",
        "services": [
            {
                "service": "tve-manager",
                "module": "degradation-dashboard",
                "name": "Degradation Dashboard",
                "path": "/degradationDashboard",
                "description": "For marking MVPD performance as degraded",
                "icon": "minus circle",
                "environment": process.env.TVE_MANAGER_ENV
            },
            {
                "service": "tve-manager",
                "module": "mvpd-editor",
                "name": "MVPD Configuration Editor",
                "path": "/mvpdConfigEditor",
                "description": "Configure MVPDs",
                "icon": "wifi",
                "environment": process.env.TVE_MANAGER_ENV
            },
            {
                "service": "tve-manager",
                "module": "configuration-release",
                "name": "Configuration Release Management",
                "path": "/releaseManager",
                "description": "Create and publish new application versions",
                "icon": "code branch",
                "environment": process.env.TVE_MANAGER_ENV
            },
            {
                "service": "tve-manager",
                "module": "entity-editor",
                "name": "Entity Editor",
                "path": "/entityEditor",
                "description": "Create and modify brands, providers, platforms, and groups",
                "icon": "edit",
                "environment": process.env.TVE_MANAGER_ENV
            }
        ]
    },
    {
        "groupName": "Event Dispatcher",
        "services": [
            {
                "service": "event-framework",
                "module": "event-dispatcher",
                "name": "Save Callback",
                "path": "/saveCallback",
                "description": "Saves Callbacks for Event Dispatcher.",
                "icon": "write square",
                "environment": process.env.EVENT_DISPATCHER_ENV
            }
        ]
    },
    {
        "groupName": "Credit Notes",
        "services": [
            {
                "service": "credit-notes",
                "module": "credit-pusher",
                "name": "Credit Notes",
                "path": "/creditNotesAdmin",
                "description": "Credit Notes Manager",
                "icon": "edit outline",
                "environment": process.env.CREDIT_NOTES_ENV
            }
        ]
    },
    {
        "groupName": "Scalerator",
        "services": [
            {
                "service": "scalerator",
                "module": "ec2-scaler",
                "name": "EC2 Scaler",
                "path": "/scaleratorAdmin",
                "description": "AWS EC2 Scaling Tool",
                "icon": "edit outline",
                "environment": process.env.SCALERATOR_ENV
            }
        ]
    },
    {
        "groupName": "Documentation",
        "services": [
            {
                "name": "API Documentation",
                "path": "https://apidocs.ngtv.io/",
                "external": true,
                "description": "Documentation about integrating with our services",
                "icon": "book",
                "environment": null,
                "open": true
            },
            {
                "name": "Confluence",
                "path": "https://wbddigital.atlassian.net/wiki/spaces/MPDTAS/pages/179081110/Media+Services",
                "external": true,
                "description": "Business documentation",
                "icon": "file alternate",
                "environment": null,
                "open": true
            },
            {
                "name": "Services Usage",
                "path": "https://g-c4b332b910.grafana-workspace.us-east-1.amazonaws.com/d/h800_k5Gz/services-usage-reports?orgId=1",
                "external": true,
                "description": "Media Services Dashboard Usage allowing visualization by Brand and Service",
                "icon": "chart pie",
                "environment": null,
                "open": true
            }
        ]
    }
];

class AppRouter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldRedirect: false,
            authenticated: false,
            authState: "UNINITIALIZED",
            authUrl: undefined,
            user: {},
            redirectUri: window.location.href,
            authCheckInterval: undefined,
            pathname: "/",
            permissions: [],
            userPermissions: [],
            loadingPermissions:  "UNINITIALIZED"
        };

        this.triggerToast = this.triggerToast.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
        this.changeAuthState = this.changeAuthState.bind(this);
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
        this.setPathname = this.setPathname.bind(this);
    }

    componentDidMount() {
        AuthProvider.init({baseURL: process.env.SYNAPSE_CORE_URL}).then(initialized => {
            if (!initialized) throw Error("Could not connect to the authentication service.");
        }).then( async () => {
            let user = await AuthProvider.queryUserData(this.state.redirectUri);
            console.log(`(index.js componentDidMount) authstate was ${this.state.authState}`);
            this.setState({authState: "IN_PROGRESS"});
            return user;
        }).then(user => {
            console.log("index.js — componentDidMount -> AuthProvider -> getUserData: ", user);
            if (!user.isLoggedIn) {
                if (this.state.authState === "UNAUTHORIZED") {
                    console.log("(index.js componentDidMount) authstate was UNAUTHORIZED");
                } else {
                    this.setState({
                        shouldRedirect: true,
                        authUrl: `${user.location}`
                    }, async () => {
                        this.setState({user: await AuthProvider.queryUserData(), authenticated: true});
                    });
                }
            } else {
                this.setState({user, authState: "AUTHORIZED"}, () => {
                    console.log("(index.js componentDidMount) set authState to AUTHORIZED");
                    if (!this.state.authCheckInterval) {
                        this.setState({authCheckInterval: setInterval(() => {
                                AuthProvider.queryUserData().then(user => {
                                    if (!user.isLoggedIn) {
                                        this.setState({authState: "UNAUTHORIZED"}, () => {
                                            console.log("(index.js handleVisibilityChange) set authState to UNAUTHORIZED");
                                            if (this.state.authCheckInterval) {
                                                clearInterval(this.state.authCheckInterval);
                                                this.setState({authCheckInterval: undefined});
                                            }
                                        });
                                    }
                                });
                            }, process.env.USER_DATA_QUERY_INTERVAL || 300000)
                        });
                    }

                    this.setState({loadingPermissions: true}, () => {
                        AuthProvider.getAllPermissions().then(response => {
                            console.log("src/index.js: permissions response: ", response);
                            this.setState({permissions: response});
                        }).finally(() => {
                            this.setState({loadingPermissions: false});
                        });
                    });
                });
            }
        }).catch(error => {
            this.triggerToast("Error", error.toString());
        });
    }

    componentWillUnmount() {
        if (this.state.authCheckInterval) {
            clearInterval(this.state.authCheckInterval);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const userUpdated = JSON.stringify(prevState.user) !== JSON.stringify(this.state.user) && this.state.user.hasOwnProperty("authz");
        const userHasValidAuthz = Array.isArray(this.state.user.authz);
        const permissionsUpdated = JSON.stringify(prevState.permissions) !== JSON.stringify(this.state.permissions);
        if (userUpdated && userHasValidAuthz || permissionsUpdated) {
            const userAuthZ = this.state.user.authz.slice();
            const updatedUserPermissions = [];
            for (const permission of this.state.permissions.slice()) {
                console.log("checking permission: ", permission);
                if (userAuthZ.includes(permission.urn)) {
                    updatedUserPermissions.push(Object.assign({}, permission));
                }
            }

            console.log("updated user permissions: ", updatedUserPermissions);
            this.setState({userPermissions: updatedUserPermissions});
        }
    }

    handleVisibilityChange(isVisible) {
        if (isVisible && this.state.authState !== "UNAUTHORIZED") {
            // check if user is logged in. If not, redirect to 403
            AuthProvider.queryUserData().then(user => {
                console.log(`(index.js handleVisibilityChange) authState: ${this.state.authState}; isLoggedIn? ${AuthProvider.isLoggedIn()}; `);
                if (!AuthProvider.isLoggedIn()) {
                    this.setState({authState: "UNAUTHORIZED"}, () => {
                        console.log("(index.js handleVisibilityChange) set authState to UNAUTHORIZED");
                        if (this.state.authCheckInterval) {
                            clearInterval(this.state.authCheckInterval);
                            this.setState({authCheckInterval: undefined});
                        }
                    });
                }
            });
        }
    }

    getAllPotentialProperties = (property, list) => {
        if (list && Array.isArray(list) && property) {
            return list.reduce((accumulator, profile) => {
                if (!accumulator.includes(profile[property])) {
                    accumulator.push(profile[property]);
                }
                return accumulator;
            }, []);
        } else {
            return [];
        }
    };

    async changeAuthState(authState) {
        console.log("(index.js changeAuthState) new authState: ", authState);
        this.setState({authState}, () => {
            console.log("(index.js changeAuthState) changed authState: ", authState);
            return authState;
        });
        return authState;
    }

    async checkIfAuthorized(permissions) {
        return AuthProvider.checkIfAuthorized(permissions);
    }

    listToDatalist(list) {
        return Array.from(list).map(item => <option key={item} value={item} />);
    }

    triggerToast(title, error) {
        console.log('Index.js — TriggerToast error: ', error);
        toast({
            type: 'error',
            title: title,
            description: error
        });
    }

    toggleToast = (title, message, toastType) => {
        console.log(`type: ${toastType}`);
        toast({
            type: toastType,
            title: title,
            description: message
        });
    };

    async authWindowUnloaded() {
        this.setState({ shouldRedirect: false });
    }

    setPathname(pathname) {
        this.setState({pathname});
    }

    render() {
        return (
            <PageVisibility onChange={this.handleVisibilityChange}>
                <Router>
                    <div style={{height: "100%"}}>
                        {
                            !AuthProvider.isLoggedIn() ? "" :
                                <Navbar
                                    user={this.state.user}
                                    serviceGroups={SERVICE_GROUPS}
                                    setPathname={this.setPathname}
                                    pathname={this.state.pathname}
                                    permissions={this.state.permissions}
                                />
                        }
                        <SemanticToastContainer
                            position="top-center"
                        />
                        {this.state.authState === "UNAUTHORIZED" ?
                            <Unauthorized /> :
                            <div>
                                <Switch>
                                    <Route exact path="/login" render={() => <Login redirectUri={this.state.redirectUri || "/"} location={this.state.authUrl} />} />
                                    <Route exact path="/" render={() => (
                                        <Dashboard
                                            user={this.state.user}
                                            authenticated={this.state.authenticated}
                                            checkIfAuthorized={this.checkIfAuthorized}
                                            serviceGroups={SERVICE_GROUPS}
                                            permissions={this.state.permissions}
                                            loadingPermissions={this.state.loadingPermissions}
                                        />)
                                    } />
                                    {/*<SecureRoute*/}
                                    {/*    path="/deployment"*/}
                                    {/*    user={this.state.user}*/}
                                    {/*    getAllPotentialProperties={this.getAllPotentialProperties}*/}
                                    {/*    listToDatalist={this.listToDatalist}*/}
                                    {/*    toast={this.toggleToast}*/}
                                    {/*    authenticated={this.state.authenticated}*/}
                                    {/*    checkIfAuthorized={this.checkIfAuthorized}*/}
                                    {/*    component={DeploymentMasterDetailView}*/}
                                    {/*    authState={this.state.authState}*/}
                                    {/*    setPathname={this.setPathname}*/}
                                    {/*    service="admin"*/}
                                    {/*    module="deployment-master"*/}
                                    {/*    loadingPermissions={this.state.loadingPermissions}*/}
                                    {/*    permissions={this.state.permissions}*/}
                                    {/*    userPermissions={this.state.userPermissions}*/}
                                    {/*/>*/}
                                    <SecureRoute
                                        path="/ad-configuration"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.triggerToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={AdConfigurationMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="medium"
                                        module="ad-configuration"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/origin-cdn-mapper"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.triggerToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={OriginCDNMapperMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="medium"
                                        module="origin-cdn-mapper"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/smpAudit"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.triggerToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated = {this.state.authenticated}
                                        component={SMPAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="smp"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/smpMD"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.triggerToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated = {this.state.authenticated}
                                        component={SMPMetadataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="smp"
                                        module="metadata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/ara"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.triggerToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={ARAMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admin"
                                        module="ara"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/relaysAudit"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={RelaysAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="relays"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/relaysAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={RelaysAdminMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="relays"
                                        module="admin"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/admiralAudit"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={AdmiralAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admiral"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/admiralAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={AdmiralAdminMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admiral"
                                        module="admin"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/admiralGroups"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={AdmiralGroupsMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admiral"
                                        module="groups"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/campaignManager"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={CampaignManager}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admiral"
                                        module="campaign-manager"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="admin"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineAudit"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineRundownCMS"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineRundownCMSMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="cms"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineScopeCMS"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineScopeCMSMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="cms"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineCMSAudit"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineCMSAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="cms-audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineCMSDefaultImages"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineDefaultImagesMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="cms-default-images"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineShowSearch"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineShowSearchMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="show-search"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/timeMachineTaxonomyViewer"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TimeMachineTaxonomyViewer}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="time-machine"
                                        module="taxonomy-viewer"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/tokenfp"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={TokenFpMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="token"
                                        module="free-preview"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/tokenmd"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={TokenMdMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="token"
                                        module="metadata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/mediumAdmin"
                                        user={this.state.user}
                                        toast={toast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={MediumAdminMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="medium"
                                        module="admin"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/assetRegistration"
                                        user={this.state.user}
                                        toast={toast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={MediumAssetRegistration}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="medium"
                                        module="asset-registration"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/error-report"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.triggerToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={ErrorReportMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admin"
                                        module="error-report"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/userMgt"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={UserManagementMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="synapse"
                                        module="users"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/routeMgt"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={RouteMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="synapse"
                                        module="routes"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/synapseMetadata"
                                        user={this.state.user}
                                        getAllPotentialProperties={this.getAllPotentialProperties}
                                        listToDatalist={this.listToDatalist}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={SynapseMetadataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="synapse"
                                        module="metadata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/adobeTempPass"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authenticated={this.state.authenticated}
                                        component={AdobeTempPassMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="adobe-temp-pass"
                                        module="reset-tool"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                        open
                                    />
                                    <SecureRoute
                                        path="/mediumAudit"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={MediumAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="medium"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/tokenAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TokenAdminMasterView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="token"
                                        module="tools"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                        open
                                    />
                                    <SecureRoute
                                        path="/tokenGeofencing"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TokenGeofencingMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="token"
                                        module="geofencing"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/tokenStreamSettings"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TokenStreamSettingsMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="token"
                                        module="stream-settings"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/freeview"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authInitialized={this.state.authInitialized}
                                        component={FreeviewTokenDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="freeview"
                                        module="reset"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/freeviewAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        authInitialized={this.state.authInitialized}
                                        component={FreeviewMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="freeview"
                                        module="admin"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/spotlightMD"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={SpotlightMetadataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="spotlight"
                                        module="metadata"
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                        loadingPermissions={this.state.loadingPermissions}
                                    />
                                    <SecureRoute
                                        path="/sbpMD"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={StateBasedPlayMetadata}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="sbp"
                                        module="metadata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/contentSchemata"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={ContentSchemataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="sbp"
                                        module="content-schemata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/listSchemata"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={ListSchemataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="sbp"
                                        module="list-schemata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/s2s"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={S2SToolsMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admin"
                                        module="s2s-tools"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                        open
                                    />
                                    <SecureRoute
                                        path="/knox"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={KnoxMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="knox"
                                        module="key-pairs"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                        open
                                    />
                                    <SecureRoute
                                        path="/sundial/:mediaId?"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={SundialMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="admin"
                                        module="sundial"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                        open
                                    />
                                    <SecureRoute
                                        path="/blackout"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={BlackoutSoupMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="blackout"
                                        module="soup"
                                        open
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/degradationDashboard"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={DegradationDashboardMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="tve-manager"
                                        module="degradation-dashboard"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/mvpdConfigEditor"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={MVPDConfigEditorMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="tve-manager"
                                        module="mvpd-editor"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/releaseManager"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={ApplicationReleaseManager}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="tve-manager"
                                        module="configuration-release"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/entityEditor"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={TVEManagerEntityEditor}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="tve-manager"
                                        module="entity-editor"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/limbo"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={LimboMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="limbo"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/cachePurge"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={CDNToolsMasterView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="medium"
                                        module="cdn"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/geo"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={GeoMetadataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="geo"
                                        module="metadata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/geoOverrides"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={GeoOverridesMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="geo"
                                        module="overrides"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/consensoAudit"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={ConsensoAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="consenso"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/consensoMD"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={ConsensoMetadataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="consenso"
                                        module="metadata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/consensoAssets"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={ConsensoAssetsMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="consenso"
                                        module="assets"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/accioMetadata"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={AccioMetadataMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="accio"
                                        module="metadata"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/accioMaturity"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={AccioMaturityMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="accio"
                                        module="maturity"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/accioEvents"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={AccioEventsAdminMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="accio"
                                        module="events"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/accioAudit"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={AccioEventsAuditMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="accio"
                                        module="audit"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/accioAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={AccioAdminMasterDetailView}
                                        authState={this.state.authState}
                                        setPathname={this.setPathname}
                                        service="accio"
                                        module="settings"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/saveCallback"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={EventDispatcherMasterDetailView}
                                        authState={this.state.authState}
                                        service="event-framework"
                                        setPathname={this.setPathname}
                                        module="event-dispatcher"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/creditNotesAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={CreditNotesAdminMasterDetailView}
                                        authState={this.state.authState}
                                        service="credit-notes"
                                        setPathname={this.setPathname}
                                        module="credit-pusher"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <SecureRoute
                                        path="/scaleratorAdmin"
                                        user={this.state.user}
                                        toast={this.toggleToast}
                                        authenticated={this.state.authenticated}
                                        checkIfAuthorized={this.checkIfAuthorized}
                                        component={ScaleratorAdminMasterDetailsView}
                                        authState={this.state.authState}
                                        service="scalerator"
                                        setPathname={this.setPathname}
                                        module="ec2-scaler"
                                        loadingPermissions={this.state.loadingPermissions}
                                        permissions={this.state.permissions}
                                        userPermissions={this.state.userPermissions}
                                    />
                                    <Route path="*" render={() => <NotFound />} />
                                </Switch>
                            </div>
                        }
                        {
                            this.state.shouldRedirect &&
                            <Redirect to="/login" />
                        }
                    </div>
                </Router>
            </PageVisibility>
        );
    }
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<AppRouter />);

module.hot.accept();
